<template>
  <el-table
    :show-header="false"
    :data="banksData"
    class="banks-list"
    style="width: 100%">
    <el-table-column
      prop="date"
      label=""
      width="100"

      v-slot="icon_path">
      <img :src="icon_path.row.icon_path" width="31" height="31" alt="">
    </el-table-column>
    <el-table-column
      prop="name"
      label=""
      width="350"
      v-slot="bank">
      <p class="fw-boldest fs-3">{{ bank.row.name }}</p>
    </el-table-column>
<!--    <el-table-column-->
<!--      v-if="isRegisteredBanks"-->
<!--      prop="name"-->
<!--      label=""-->
<!--      width="180"-->
<!--      v-slot="score">-->
<!--      <div v-if="score.row.score" class="d-flex">-->
<!--        <p v-if="score.row.score > 3.99" class="fw-boldest fs-3 text-success bg-light-success d-inline-block px-2">{{ score.row.score }}</p>-->
<!--        <p v-if="score.row.score < 2.99" class="fw-boldest fs-3 text-danger bg-light-danger d-inline-block px-2">{{ score.row.score }}</p>-->
<!--        <p v-if="score.row.score < 3.99 && score.row.score > 2.99" class="fw-boldest fs-3 text-warning bg-light-warning d-inline-block px-2">{{ score.row.score }}</p>-->
<!--        <p class="text-gray-500 fw-bold ms-15 fs-3 ">4.21</p>-->
<!--      </div>-->
<!--      <p v-else class="fs-4 text-gray-400">{{$t('NOT ACTIVE')}}</p>-->
<!--    </el-table-column>-->
    <el-table-column
      prop="address"
      v-slot="countries">
      <span class="fs-3" v-for="(country, i) in countries.row.countries">
        {{ i < countries.row.countries.length - 1 ? country + ', ' : country }}
      </span>
    </el-table-column>
  </el-table>
</template>

<script>

export default {
  name: "BanksList",
  components: {},
  props: ["isRegisteredBanks", 'banksData'],
  data() {
    return {
    };
  }
};
</script>
<style lang="scss">
.banks-list {
  @media (max-width: 768px) {
    &.el-table::before {
      display: none;
    }
    .mobile-title {
      display: inline-block;
      margin-right: 10px;
    }
    .el-table__header-wrapper {
      display: none;
    }
    .el-table__body {
      display: flex;
      width: 100% !important;
      colgroup {
        display: none;
      }
      .el-table__row {
        display: flex;
        flex-wrap: wrap;
        &:not(:last-child) {
          border-bottom: 1px solid #EFF2F5;
          padding-bottom: 15px;
        }
        .el-table__cell {
          border: none !important;
          &:nth-child(1) {
            width: 70px;
          }
          &:nth-child(2) {
            width: calc(100% - 70px);
            display: flex;
            align-items: center;
          }
          &:nth-child(3) {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            .cell {
              display: flex;
              flex-wrap: wrap;
              &.el-tooltip {
                width: auto !important;
                display: flex;
                flex-wrap: wrap;
              }
              p {
                margin-bottom: 0 !important;
              }
              span {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

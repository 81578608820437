<template>
  <div class="card pt-3">
    <div class="card-header cursor-pointer px-0">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{ $t("Bank List") }}</h3>
        <span class="text-with-bg ms-3">{{banksData.length}}</span>
      </div>
      <div class="view-my-banks">
        <router-link to="/business/settings/user-settings/overview#active-banks" class="btn main-btn-outline">
          {{ $t('View my banks') }}
        </router-link>
      </div>
    </div>
    <BanksList
      :banksData="banksData"
      :isRegisteredBanks="true"
    />
  </div>
</template>
<script>
import BanksList from "@/buying-teams/shared-components/settings/BanksList";
import store from "@/store";
export default {
  name: "RegisteredBanks",

  components: {
    BanksList,
  },

  data() {
    return {};
  },

  computed:{
    banksData() {
      return store.getters.businessBanks;
    }
  }

};
</script>
<style lang="scss" scoped>
.view-my-banks {
  display: flex;
  align-items: center;
  margin-right: 20px;
  a {
    background: rgba(67, 91, 244, 0.06) !important;
  }
}
</style>
